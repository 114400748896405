import {Component, Input, OnChanges} from '@angular/core';
import {UserSerializer} from '../../../@core/api.service';
import {Human} from '../../../models/human';
import {stringToColor} from '../../../utils/color.utils';
import {removeQuotes} from '../../../utils/string.utils';

@Component({
    selector: 'app-user-icon',
    template: `
        <span class="user-icon user-icon-{{size}} {{classList}}" [ngbTooltip]="hasTooltip ? tooltip : null" [placement]="tooltipPlacement" [style.background]="color">
            <span *ngIf="addOnIcon" class="bubble"><fas [i]="addOnIcon"></fas></span>
            {{initials}}
        </span>
    `,
    styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent implements OnChanges {
    @Input() user: UserSerializer | string;
    @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
    @Input() tooltipPlacement: 'top' | 'left' | 'bottom' | 'right' = 'left';
    @Input() hasTooltip = true;
    @Input() addOnIcon: string;
    @Input() tooltipInfo: string;
    @Input() classList: string;
    name: string;
    initials: string;
    color: string;
    tooltip: string;

    ngOnChanges() {
        if (typeof this.user === 'string') {
            this.name = this.user;
            this.initials = this.user.split(', ').map(x => x.charAt(0)).join('');
            this.color = stringToColor(this.user);
        } else {
            this.name = Human.getName(this.user, 'firstNameFirst');
            this.initials = (removeQuotes(this.user?.first_name) || '').charAt(0) + (removeQuotes(this.user?.last_name) || '').charAt(0);
            this.color = stringToColor((this.user?.id || '') + this.name);
        }
        if (this.hasTooltip) this.tooltip = this.name + (this.tooltipInfo ? ` - ${this.tooltipInfo}` : '');
    }
}
